<template>
  <div class="container">
    <GeneralTable :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      tableName="LoginLog"
    >
      <template slot="operate"
        slot-scope="{item}">
        <div class="operate">
          <el-button type="text"
            @click="handleDelSingle(item)">删除</el-button>
        </div>
      </template>
    </GeneralTable>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import { getLikeLoginLog, loginLogDel, exportLoginLog, loginLogDelBatch } from '@/api/SystemData.js';
export default {
  components: { GeneralTable },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      column: [{
        label: '序号',
        type: 'index',
        isShow: true,
        fixed: 'left'
      },
      {
        label: "登录户名",
        prop: "userName",
        isShow: true,
      },
      {
        label: "IP",
        prop: "ip",
        isShow: true,
      },
      {
        label: "所在地区",
        prop: "address",
        isShow: true,
      },
      {
        label: "浏览器",
        prop: "browser",
        isShow: true,
      },
      {
        label: "登陆时间",
        prop: "operateTime",
        isShow: true,
      },
      {
        label: "操作",
        prop: "operate",
        isShow: true,
        width: 100,
        fixed: 'right',
        isSlot: true,
      }
      ],
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: false, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [{
          label: '登录用户',
          prop: 'userName',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入登录用户"
        },
        {
          label: 'IP',
          prop: 'ip',
          formType: 'input',
          maxLength: 36,
          clearable: true,
          placeholder: "请输入IP"
        }, {
          label: '日期',
          formType: 'daterange',
          clearable: true,
            format: 'yyyy.MM.dd',
          prop: ['beginTime', 'endTime']
        }]
      },
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    getList(data) {
      getLikeLoginLog(data).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleSearch(data) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      // if (data.keyword) {
      //   data.userName = data.keyword;
      //   data.ip = data.keyword;
      // }
      if (data.beginTime && data.endTime) {
        data.beginTime += ' 00:00:00';
        data.endTime += ' 00:00:00';
      }
      delete data.pageSize;
      delete data.pageNum;
      delete data.keyword;
      this.getList(data);
    },
    handleDelSingle(data) {
      this.$confirm('确认删除该日志?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        loginLogDel(data.id).then(res => {
          if (res) {
            this.$message({
              message: res.msg,
              type: 'success'
            });
          }
          this.$refs.GeneralTable.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm('确认删除日志?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = '';
          data.forEach(element => {
            ids += element.id + ',';
          });
          ids = ids.slice(0, ids.length - 1);
          loginLogDelBatch(ids).then(res => {
            if (res) {
              this.$message({
                message: res.msg,
                type: 'success'
              });
            }
            this.$refs.GeneralTable.getList();
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的日志！');
      }
    },
    handleExport(data) {
      let ids = [];
      data.forEach(element => {
        ids.push(element.id);
      });
      exportLoginLog({ ids: ids.join(',') }).then(res => {
        if (res) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
      });
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
